import * as React from 'react'
import { Helmet } from "react-helmet"
import '../styles/styles.css'
import Navbar from '../components/navbar'
import Faq from '../components/faq'
import Check from '../components/check'
import WhatIs from '../components/whatIs'
import Specs from '../components/specs'
import Hero from '../components/hero'
import Trust from '../components/trust'
import WhyWB from '../components/whyWB'
import About from '../components/about'
import Contact from '../components/contact'
import Footer from '../components/footer'

const IndexPage = () => {
  React.useEffect(() => {
    let navItems = document.querySelectorAll(".innerNav")
    navItems.forEach(navItem =>
      navItem.addEventListener("click", (e) => {
        e.preventDefault();
        let targetElem = document.querySelector(navItem.hash)
        if (targetElem) {
          let divFromTop = targetElem.getBoundingClientRect().top + window.scrollY - 76
          window.scrollTo({ top: divFromTop, behavior: 'smooth' })
        }
      }))
  }, [])

  return (
    <div>
      <Helmet
        title="Whistleblowers"
        meta={[
          { name: 'description', content: 'online application with anonymous reporting channels, which employees can safely use to report problems and illegalities to an organization, as provided by the EU.' },
          { name: 'keywords', content: 'Whistleblowing, report illegalities, safe employees' }
        ]}
      >
        <html lang="el" />
      </Helmet>
      <Navbar />
      <main>
        <Hero />
        <WhatIs />
        <Check />
        <Specs />
        <Trust />
        <WhyWB />
        <About />
        <Faq />
        <Contact />
      </main>
      <Footer />
    </div>
  )
}

export default IndexPage