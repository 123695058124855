import React from 'react'
import CheckImg from "../images/Frame-3.png"

export default function Check() {
    return (
        <div className="checkRelative">
            <section className="checkSection container-fluid d-flex justify-content-xxl-center p-5">
                <div className="checkBox d-flex flex-column align-items-center me-4">
                    <img loading="lazy"
                        alt="check"
                        src={CheckImg}
                        className="mt-4"
                    />
                    <p className="mt-4 px-4 text-center">Συμμορφωθείτε με τους κανονισμούς της Ε.Ε. και την ελληνική νομοθεσία</p>
                </div>
                <div className="checkBox d-flex flex-column align-items-center me-4">
                    <img loading="lazy"
                        alt="check"
                        src={CheckImg}
                        className="mt-4"
                    />
                    <p className="mt-4 px-4 text-center">Αποκτήστε μια ανοικτή και ασφαλή δίοδο καταγγελιών</p>
                </div>
                <div className="checkBox d-flex flex-column align-items-center me-4">
                    <img loading="lazy"
                        alt="check"
                        src={CheckImg}
                        className="mt-4"
                    />
                    <p className="mt-4 px-4 text-center">Λύστε υποθέσεις</p>
                </div>
                <div className="checkBox d-flex flex-column align-items-center me-4">
                    <img loading="lazy"
                        alt="check"
                        src={CheckImg}
                        className="mt-4"
                    />
                    <p className="mt-4 px-4 text-center">Προστατεύστε τον οργανισμό σας</p>
                </div>
                <div className="checkBox d-flex flex-column align-items-center me-4">
                    <img loading="lazy"
                        alt="check"
                        src={CheckImg}
                        className="mt-4"
                    />
                    <p className="mt-4 px-4 text-center">Προστατεύστε τους ανθρώπους που αναλαμβάνουν δράση!</p>
                </div>
            </section>
        </div>

    )
}
