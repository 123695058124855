import React, { useState, useCallback } from 'react'
import $ from 'jquery'

export default function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")

  const handleNameChange = useCallback(e => setName(e.target.value), [])
  const handleEmailChange = useCallback(e => setEmail(e.target.value), [])
  const handlePhoneChange = useCallback(e => setPhone(e.target.value), [])
  const handleSubjectChange = useCallback(e => setSubject(e.target.value), [])
  const handleMessageChange = useCallback(e => setMessage(e.target.value), [])

  const googleUrl = "https://docs.google.com/forms/u/0/d/e/1FAIpQLSdO1Ia7Gv6_akF_fzIANsOhEgv9oAUpB_WmaF0JvcBbqNANFA/formResponse"
  const postToGoogle = () => {
    $.ajax({
      url: googleUrl,
      data: {
        "entry.331064115": name,
        "entry.1861194406": email,
        "entry.328116458": phone,
        "entry.1653496634": subject,
        "entry.1556848250": message
      },
      type: "POST",
      dataType: "xml",
      success: function (d) {
        $('#contact').trigger('reset');
      },
      error: function (x, y, z) {
        $('#contact').trigger('reset');
      }
    })
    setName("")
    setEmail("")
    setPhone("")
    setSubject("")
    setMessage("")
  }
  return (
    <section id="contact" className="bg-white py-5">
      <div className="container p-md-4">
        <h3 className="text-primary my-4 fs-1">Επικοινωνία</h3>
        <div className="row">
          <div className="col-lg-6">
            <div className="row">
              <div className="form-floating col-sm-6 mb-4">
                <input type="text" className="form-control bg-light px-3 border-0" id="name" placeholder="Όνομα" name="entry.331064115" data-name="name" value={name} onChange={handleNameChange} />
                <label htmlFor="name" className="text ms-3">Όνομα</label>
              </div>
              <div className="form-floating col-sm-6 mb-4">
                <input type="email" className="form-control bg-light px-3 border-0" id="email" placeholder="Email" name="entry.1861194406" data-name="email" onChange={handleEmailChange} value={email} />
                <label htmlFor="email" className="text ms-3">Email</label>
              </div>
            </div>
            <div className="row">
              <div className="form-floating col-sm-6 mb-4">
                <input type="text" className="form-control bg-light px-3 border-0" id="phone" placeholder="Τηλέφωνο" name="entry.328116458" data-name="phone" onChange={handlePhoneChange} value={phone} />
                <label htmlFor="phone" className="text ms-3">Τηλέφωνο</label>
              </div>
              <div className="form-floating col-sm-6 mb-4">
                <input type="text" className="form-control bg-light px-3 border-0" id="subject" placeholder="Θέμα" name="entry.1653496634" data-name="subject" onChange={handleSubjectChange} value={subject} />
                <label htmlFor="subject" className="text ms-3">Θέμα</label>
              </div>
            </div>
            <div className="row">
              <div className="form-floating col">
                <textarea type="text" className="messageArea form-control bg-light px-3 border-0" id="message" placeholder="Μήνυμα" name="entry.1556848250" data-name="message" onChange={handleMessageChange} value={message} />
                <label htmlFor="message" className="text ms-3">Μήνυμα</label>
              </div>
            </div>
            <div className="row my-4">
              <div>
                <button className="btn btn-primary mt-lg-4" data-bs-toggle="modal" data-bs-target="#contactModal" onClick={postToGoogle}>ΑΠΟΣΤΟΛΗ</button>
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-flex flex-column ps-lg-5">
            <h4 className="text my-3">Κεντρικά</h4>
            <a
              href="https://www.google.com/maps/place/Think+Plus+-+Advertising/@38.0381106,23.8048869,17z/data=!3m1!4b1!4m5!3m4!1s0x14a198de25b8ef7b:0xc223731778f7ddf7!8m2!3d38.0381106!4d23.8070756"
              className="contactDetails"
              target="_blank"
              rel="noreferrer"
            >Αμαρουσίου Χαλανδρίου 36Β, 15125 Μαρούσι</a>
            <a
              className="contactDetails mb-1"
              href="mailto:apps@syntogether.com"
            >apps@syntogether.com</a>
            <a className="contactDetails d-block" href="tel:+302106101478">
              +30 210 6101 478</a>
          </div>
        </div>
      </div>
      <div className="modal fade" id="contactModal" tabIndex="-1" aria-labelledby="contactModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered rounded-3">
          <div className="modal-content rounded-3 border-0 p-4">
            <div className="modal-header rounded-3 border-0">
              <h4 className="modal-title text" id="contactModalLabel">Ευχαριστούμε για την επικοινωνία</h4>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
