import React from 'react'
import Spec1 from "../images/Group 67.jpg"
import Spec2 from "../images/allinone.jpg"
import Spec3 from "../images/Group 70.jpg"

export default function Specs() {
  return (
    <div id="specs">
      <section className="mb-5">
        <div className="container ">
          <div className="row mb-4">
            <div className="col-md-6 d-flex flex-column justify-content-center px-4 mb-3">
              <h3 className="text-primary mb-4 fs-1">Εύχρηστο εργαλείο αναφοράς & πλατφόρμα whistleblowing</h3>
              <p className="mb-0">Παράνομες ενέργειες, παρενοχλήσεις ή εκβιασμοί - αυτά επηρεάζουν τους πάντες. Πέραν αυτού, δεν είναι πάντα εύκολο να εκφραστούν κατά πρόσωπο ανησυχίες για τέτοια θέματα. Με το Whistleblowers.gr έχετε την ευκαιρία να αντιμετωπίσετε αυτά τα θέματα ή να ζητήσετε από τους καταγγέλλοντες να εγείρουν ιδέες για βελτιώσεις.
              </p>
              <p>Ανώνυμα, διαδικτυακά και κυρίως, με ασφάλεια.</p>
            </div>
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <img loading="lazy"
                alt="wistleblowers"
                src={Spec1}
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="mb-5">
        <div className="container px-4">
          <div className="row mb-4 d-flex flex-column-reverse flex-md-row">
            <div className="col-md-6 d-flex  justify-content-center align-items-center">
              <img loading="lazy"
                alt="wistleblowers"
                src={Spec2}
                className="img-fluid"
              />
            </div>
            <div className="col-md-6 d-flex flex-column justify-content-center">
              <h3 className="text-primary fs-1">Η all-in-one λύση</h3>
              <ul>
                <li>Αποδεδειγμένη ασφάλεια πρόσβασης.</li>
                <li>Σύγχρονη κρυπτογράφηση και ασφαλή data centres.</li>
                <li>Πιστοποίηση ISO 27001.</li>
                <li>Ψευδωνυμοποίηση/ανωνυμοποίηση σύμφωνα με την προστασία δεδομένων προσωπικού χαρακτήρα GDPR. (Αρ.17)</li>
                <li>
                  Απαγόρευση επεξεργασίας μη σχετιζόμενων δεδομένων.
                </li>
                <li>Προστασία δεδομένων από εξωτερικούς παράγοντες.</li>
                <li>Πλήρως εναρμονισμένο με τον Κανονισμό για τα Προσωπικά Δεδομένα. (GDPR)</li>
                <li>
                  Τεκμηρίωση όλων των εισερχόμενων καταγγελιών σε συμφωνία με τις επιταγές της εμπιστευτικότητας. (Αρ.18)
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="mb-5">
        <div className="container px-4">
          <div className="row">
            <div className="col-md-6 d-flex flex-column justify-content-center">
              <h3 className="text-primary fs-1">Είμαστε στο πλευρό σας</h3>
              <ul>
                <li>Ενεργή υποστήριξη σε νομικά και λειτουργικά θέματα.</li>
                <li>Tips και αξιόπιστες νομικές συμβουλές κατά την υλοποίηση.</li>
                <li>Εκτενές customisation - Πλήρως προσαρμοσμένη λύση στις ανάγκες της επιχείρησης σας.</li>
                <li>Επιπρόσθετη υποστήριξη σε περίπλοκα θέματα όπως η προστασία δεδομένων.</li>
                <li>
                  Απόλυτη ασφάλεια πληροφοριών και επικοινωνία με τους εκπροσώπους του προσωπικού.
                </li>
                <li>Ολιστική προσέγγιση για την ανώτερη ασφάλεια κάθε πτυχής της επιχείρησης σας.</li>
              </ul>
            </div>
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <img loading="lazy"
                alt="wistleblowers"
                src={Spec3}
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
    </div>

  )
}
