import React from 'react'
import WhyWB1 from "../images/Group 82.jpg"
import WhyWB2 from "../images/Group 83.jpg"
import WhyWB3 from "../images/Group 84.jpg"
import WhyWB4 from "../images/Group 85.jpg"
import WhyWB5 from "../images/Group 86.jpg"
import WhyIcon1 from "../images/Frame-2.png"
import WhyIcon2 from "../images/Frame-1.png"
import WhyIcon3 from "../images/Frame-5.png"
import WhyIcon4 from "../images/Frame-6.png"

export default function WhyWB() {
    return (
        <section id="whyWB" className="bg-light py-5">
            <div className="d-flex">
                <div className="whyCard">
                    <img loading="lazy"
                        alt="wistleblowers"
                        src={WhyWB1}
                        className="whyCardImage img-fluid"
                    />
                    <div className="cardDetails px-3 px-md-5">
                        <h3 className="text-primary mb-2 mb-md-4 fs-1">Γιατί Whistleblowers.gr</h3>
                        <h6 className="mb-0 fs-2">Η πιο απλή, εύκολη και ασφαλής λύση για το Whistleblowing.
                        </h6>
                        <h6 className="mb-3 mb-md-5 fs-2">Πλήρως συμβατή με την Οδηγία της Ε.Ε.</h6>
                        <ul>
                            <li>Ιδανική και ολοκληρωμένη απάντηση στις Ευρωπαϊκές και ελληνικές νομικές απαιτήσεις.</li>
                            <li>Προστατεύει τη φήμη της επιχείρησης σας.</li>
                            <li>Αποτρέπει από ανήθικες, παράνομες ενέργειες και κάθε κακοποίηση.</li>
                            <li>
                                Παρουσιάζει μια συστημική διαφάνεια σε πιθανούς υπαλλήλους, συνεργάτες, αλλά και στο κοινωνικό σύνολο.
                            </li>
                            <li>
                                Μπορεί να γίνει βασικό μέρος του κοινωνικοηθικού κώδικα της εταιρείας σας, καθώς ενθαρρύνει την ηθική, νόμιμη και αξιοπρεπή συμπεριφορά.
                            </li>
                            <li>Παρακολουθεί στενά τον οργανισμό σας.</li>
                            <li>Περιορίζει και προστατεύει από οικονομικές ζημίες.</li>
                        </ul>
                    </div>
                </div>
                <div className="whyCard">
                    <img loading="lazy"
                        alt="wistleblowers"
                        src={WhyWB2}
                        className="img-fluid whyCardImage"
                    />
                    <div className="cardDetails px-3 px-md-5">
                        <img loading="lazy"
                            alt="wistleblowers"
                            src={WhyIcon1}
                            className="mb-2 mb-md-5 cardIcon"
                        />
                        <h6 className="mb-2 mb-md-5 text-primary fs-2">Προσαρμόσιμη φόρμα καταγγελιών</h6>
                        <ul>
                            <li>Οι εργαζόμενοι μπορούν με απόλυτη εμπιστευτικότητα να υποβάλουν οποιαδήποτε καταγγελία και να αποφύγουν τα αντίποινα. (Γραπτές και Προφορικές Αναφορές, Άρθρο 9(ΙΙ)).</li>
                            <li>
                                Η πρόσβαση στο ευαίσθητο περιεχόμενο των καταγγελιών και των παραπόνων, είναι πλήρως περιορισμένη και προστατευμένη.
                            </li>
                            <li>Όλοι οι υπάλληλοι, όπου κι αν βρίσκονται, μπορούν να καταγγείλουν 24/7, εύκολα και με ασφάλεια.</li>
                        </ul>
                    </div>
                </div>
                <div className="whyCard">
                    <img loading="lazy"
                        alt="wistleblowers"
                        src={WhyWB3}
                        className="img-fluid whyCardImage"
                    />
                    <div className="cardDetails px-3 px-md-5">
                        <img loading="lazy"
                            alt="wistleblowers"
                            src={WhyIcon2}
                            className="mb-2 mb-md-5 cardIcon"
                        />
                        <h6 className="mb-2 mb-md-5 text-primary fs-2">Διαδραστική Πλατφόρμα Διαχείρισης</h6>
                        <p className="mb-3">Με την οποία οι καταγγελίες θα αποστέλλονται εμπιστευτικά στον Διαχειριστή, που θα έχει τη δυνατότητα για έλεγχο, διόρθωση και παρέμβαση στον ενεργό διάλογο με τον πληροφοριοδότη.
                            Απόδειξη παραλαβής της καταγγελίας και έγκαιρο feedback (Αρ. 9(Ι)(b), (f))
                        </p>
                        <ul>
                            <li>Επακόλουθη επικοινωνία μέσω chat ή ασφαλούς γραμμής</li>
                            <li>Υποβολή καταγγελίας μέσω τηλεφώνου</li>
                            <li>Έξυπνες ειδοποιήσεις - έτσι ώστε κανείς να μην χάνει feedback</li>
                            <li>Κανάλι διαχείρισης καταγγελιών</li>
                            <li>Δημιουργία custom κατηγοριών</li>
                            <li>Κατηγορίες αναφοράς και προκαθορισμένες ερωτήσεις που εμποδίζουν τη συλλογή μη σχετικών δεδομένων</li>
                        </ul>
                    </div>
                </div>
                <div className="whyCard">
                    <img loading="lazy"
                        alt="wistleblowers"
                        src={WhyWB4}
                        className="img-fluid whyCardImage"
                    />
                    <div className="cardDetails px-3 px-md-5">
                        <img loading="lazy"
                            alt="wistleblowers"
                            src={WhyIcon3}
                            className="mb-2 mb-md-5 cardIcon"
                        />
                        <h6 className="mb-2 mb-md-5 text-primary fs-2">Αναφορά διαχειριστή</h6>
                        <p className="mb-3">Όπου οι Διαχεριριστές μπορούν να:
                        </p>
                        <ul>
                            <li>Παρακολουθούν τις καταγγελίες με πολλαπλούς τρόπους.</li>
                            <li>Συντονίζουν όλες τις δραστηριότητες.</li>
                            <li>Κάθε στοιχείο του καταγγέλοντος παραμένει απόρρητο.</li>
                        </ul>
                    </div>
                </div>
                <div className="whyCard">
                    <img loading="lazy"
                        alt="wistleblowers"
                        src={WhyWB5}
                        className="img-fluid whyCardImage"
                    />
                    <div className="cardDetails px-3 px-md-5">
                        <img loading="lazy"
                            alt="wistleblowers"
                            src={WhyIcon4}
                            className="mb-2 mb-md-5 cardIcon"
                        />
                        <h6 className="mb-2 mb-md-5 text-primary fs-2">Κέντρο Δεδομένων</h6>
                        <p className="mb-3">Όπου όλα τα δεδομένα αρχειοθετούνται ανώνυμα για μελλοντική χρήση, για καθορισμένη περίοδο και επαυξημένη προστασία.
                        </p>
                        <ul>
                            <li>Διαχείριση βάσης δεδομένων</li>
                            <li>Πίνακες και αναλυτικές αναφορές</li>
                            <li>Εξαγωγές και εκτυπώσεις</li>
                            <li>Αρχεία καταγραφής δραστηριοτήτων (activity logs)</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>


    )
}
