import React from 'react'
import '../styles/styles.css'
import Logo from "../images/Frame4.svg"
import Burger from "../images/burger.svg"

export default function Navbar() {
  return (
    <nav className="navbar sticky-top navbar-expand-lg navbar-light bg-light">
      <div className="container px-3 py-2 py-sm-3">
        <a className="navbar-brand" href="#top">
          <img
            alt="logo"
            src={Logo}
          /></a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <img alt="menu toggler" src={Burger} />
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            <a className="innerNav nav-item nav-link text-primary mx-2 mx-xl-3 flex-nowrap" href="#whatIs">Τι είναι</a>
            <a className="innerNav nav-item nav-link text-primary mx-2 mx-xl-3" href="#whyWB">Γιατί WB</a>
            <a className="innerNav nav-item nav-link text-primary mx-2 mx-xl-3" href="#about">Σχετικά</a>
            <a className="innerNav nav-item nav-link text-primary mx-2 mx-xl-3" href="#faq">FAQ</a>
            <div>
              <a className="innerNav btn btn-primary ms-2 ms-lg-4 ms-xl-5" href="#contact">Επικοινωνία</a>
            </div>

          </div>
        </div>
      </div>
    </nav>
  )
}
