import React from 'react'
import AboutImg from "../images/about.jpg"

export default function About() {
    return (
        <section id="about">
            <div className="container">
                <div className="row d-flex flex-column-reverse flex-md-row p-2 p-md-5">
                    <div className="aboutDetails col-md-6 d-flex flex-column justify-content-center">
                        <h3 className="text-primary fs-1">Μάθε για εμάς</h3>
                        <p>Το Whistleblowers.gr πρόκειται για την πρόταση της Think Plus στους κανονισμούς της Ελλάδας και της Ευρωπαϊκής Ένωσης σχετικά με το whistleblowing. Η Think Plus, ένα creative & software agency, συνδυάζει κάθε πτυχή του marketing και της διαφήμισης. </p>
                        <p>Ιδρυμένη το 2009 στην Αθήνα, έχει καταφέρει να αποσπάσει πολλαπλές εθνικές και διεθνείς διακρίσεις, καλύπτοντας τις ανάγκες ενός μεγάλου και ποικιλόμορφου φάσματος επιχειρήσεων: από ελληνικές μικρομεσαίες επιχειρήσεις μέχρι και brand παγκοσμίου φήμης.
                        </p>
                        <p>Με την κατάλληλη software τεχνογνωσία ανώτερου επιπέδου, σημαντική εμπειρία στον κόσμο των επιχειρήσεων, πλήρως καταρτισμένη ομάδα και με ένα μεγάλο δίκτυο αξιόπιστων συνεργατών και συμβούλων, καταλαβαίνουμε ακριβώς πώς να οικοδομήσουμε και να εφαρμόσουμε ένα πρόγραμμα ηθικής συμμόρφωσης που να είναι αποτελεσματικό και ελκυστικό, αλλά ταυτόχρονα εύχρηστο και πλήρως συμβατό με κάθε νομοθετική οδηγία.
                        </p>
                        <div><a className="innerNav btn btn-primary mb-4" href="#contact">Επικοινωνία</a></div>
                    </div>
                    <div className="col-md-6 d-flex justify-content-center align-items-center">
                        <img loading="lazy"
                            alt="wistleblowers"
                            src={AboutImg}
                            className="img-fluid mb-4"
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}
