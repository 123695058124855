import React from 'react'

export default function Faq() {
    return (
        <section id="faq" className="bg-light">
            <div className="container faqSection py-5">
                <h3 className="text-primary my-4 fs-1">FAQ</h3>
                <div className="accordion-item mb-3">
                    <h2 className="accordion-header fs-1" id="heading1">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                            Ποια είναι η οδηγία της Ε.Ε.;
                        </button>
                    </h2>
                    <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#faq">
                        <div className="accordion-body px-3 py-0 px-sm-4 p-lg-5">
                            Η Οδηγία της ΕΕ για την προστασία των πληροφοριοδοτών τέθηκε σε ισχύ στις 16 Δεκεμβρίου 2019. Τα κράτη μέλη της Ε.Ε. έχουν προθεσμία μέχρι το 2021 για να εφαρμόσουν τη νέα αυτή οδηγία στο εθνικό τους δίκτυο και τις νομικές οντότητες. Για την Ελλάδα, η Κοινοτική Οδηγία 2019/1937 αναμένεται να ενσωματωθεί στην ελληνική έννομη τάξη έως την 17.12.2021 (με εξαίρεση τις επιχειρήσεις που απασχολούν από 50 έως 249 εργαζομένους, για τις οποίες η ενσωμάτωση έχει οριστεί για την 17.12.2023). Εκ προοιμίου σημειώνεται ότι η παραπάνω Οδηγία έχει πεδίο εφαρμογής σε «νομικές οντότητες» που απασχολούν περισσότερα από 50 άτομα.
                        </div>
                    </div>
                </div>
                <div className="accordion-item mb-3">
                    <h2 className="accordion-header fs-1" id="heading2">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                            Τι είναι το whistleblowing;
                        </button>
                    </h2>
                    <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#faq">
                        <div className="accordion-body px-3 py-0 px-sm-4 p-lg-5">
                            Ως whistleblowing ορίζεται η καταγγελία ή η δημόσια αποκάλυψη στην οποία προβαίνει ένας νυν ή πρώην εργαζόμενος ή εξωτερικός συνεργάτης, σχετικά με παράνομες ή ανήθικες συμπεριφορές ή πρακτικές που λαμβάνουν χώρα εντός της εταιρίας ή του οργανισμού. Η καταγγελία μπορεί να γίνει σε εσωτερικά αρμόδιο όργανο (εσωτερικό whistleblowing) ή εξωτερικά σε μία ρυθμιστική αρχή (εξωτερικό whistleblowing) ή μέσω δημόσιας αποκάλυψης (για παράδειγμα στον τύπο). Οι μάρτυρες δημοσίου συμφέροντος (“Whistleblowers”) είναι πολύ σημαντικοί για τη διατήρηση μιας ανοιχτής και διαφανούς κοινωνίας και για την προστασία του Δικαίου της Ευρωπαϊκής Ένωσης.
                        </div>
                    </div>
                </div>
                <div className="accordion-item mb-3">
                    <h2 className="accordion-header fs-1" id="heading3">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                            Τι είναι το whistleblowers.gr;
                        </button>
                    </h2>
                    <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#faq">
                        <div className="accordion-body px-3 py-0 px-sm-4 p-lg-5">
                            Είναι μια online εφαρμογή, πλήρως συμμορφωμένη με την Οδηγία της Ε.Ε., η οποία διαθέτει ασφαλή κανάλια αναφοράς, μέσα από τα οποία οι εργαζόμενοι, αλλά και οι εξωτερικοί συνεργάτες, θα μπορούν να αναφέρουν ανώνυμα ή εμπιστευτικά και χωρίς φόβο αντιποίνων, παράνομες συμπεριφορές που λαμβάνουν χώρα εντός της εταιρείας ή του οργανισμού.
                        </div>
                    </div>
                </div>
                <div className="accordion-item mb-3">
                    <h2 className="accordion-header fs-1" id="heading4">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                            Ποιους αφορά;
                        </button>
                    </h2>
                    <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#faq">
                        <div className="accordion-body px-3 py-0 px-sm-4 p-lg-5">
                            Αφορά σε ιδιωτικούς και δημόσιους οργανισμούς. Μάλιστα, συνιστάται οι οργανισμοί να δημιουργήσουν ένα ανώνυμο σύστημα καταγγελίας πολύ πριν τεθούν σε ισχύ οι εθνικές διατάξεις, καθώς, η συμμόρφωση μπορεί να διαρκέσει από εβδομάδες έως μήνες, ανάλογα με το μέγεθος και την πολυπλοκότητα του οργανισμού.
                        </div>
                    </div>
                </div>
                <div className="accordion-item mb-3">
                    <h2 className="accordion-header fs-1" id="heading5">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                            Γιατί το χρειάζεται η επιχείρησή μου;
                        </button>
                    </h2>
                    <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#faq">
                        <div className="accordion-body px-3 py-0 px-sm-4 p-lg-5">
                            Διότι, πρώτα και κύρια, θα είναι απολύτως έννομη με κάθε ευρωπαϊκή και ελληνική οδηγία που αφορά το whistleblowing. Επίσης, διαθέτοντας μια τέτοια πλατφόρμα, μπορείτε να αναφέρετε νομικές ή εσωτερικές παραβιάσεις κανόνων και αξιών, για να βοηθήσετε στον πρόωρο εντοπισμό κινδύνων. Τέλος, το χρειάζεστε για να μπορείτε να αποφύγετε κυρώσεις και πρόστιμα που θα πλήξουν, τόσο το κεφάλαιο της επιχείρησης, όσο και τη φήμη της.
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
