import React from 'react'
import WhatMobile from "../images/whatIsMobile.png";
import WhatDesc from "../images/whatIsDesc.png";

export default function WhatIs() {
    return (
        <section id="whatIs" >
            <div className="container">
                <div className="row py-5">
                    <div className="col-md-6 d-flex justify-content-center align-items-center mb-4">
                        <div className="smallContainer">
                            <h3 className="text-primary fs-1">Τι είναι το σύστημα Whistleblowers.gr;</h3>
                            <p className="fs-5">Είναι μια online εφαρμογή με ανώνυμα κανάλια αναφοράς, τα οποία οι εργαζόμενοι μπορούν να χρησιμοποιήσουν με ασφάλεια για να καταγγείλουν προβλήματα και παρανομίες σε έναν οργανισμό, όπως προβλέπει η Οδηγία της Ε.Ε.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6 d-flex justify-content-center align-items-center">
                        <img loading="lazy"
                            alt="wistleblowers"
                            src={WhatMobile}
                            className="img-fluid d-md-none mb-3"
                        />
                        <img loading="lazy"
                            alt="wistleblowers"
                            src={WhatDesc}
                            className="img-fluid d-none d-md-block mb-3"
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}
