import React from 'react'
import Logo from "../images/Frame4.svg"
import TrustImg from "../images/trust.jpg"

export default function Trust() {
    return (
        <section className="bg-info ">
            <div className="container ">
                <div className="row d-flex flex-column-reverse flex-md-row">
                    <div className="col-md-5 px-3 d-flex flex-column justify-content-center align-items-start">
                        <img loading="lazy"
                            alt="wistleblowers"
                            src={Logo}
                            className="img-fluid mb-4 mb-lg-5 mt-md-4"
                        />
                        <div className="text fs-1 mb-4 mb-lg-5 lh-sm">
                            Εμπιστευτείτε την αξιόπιστη και έμπειρη ομάδα συμβούλων μας,
                            για ενεργή και ολιστική υποστήριξη.
                        </div>
                        <div><a className="innerNav btn btn-primary mb-4" href="#contact">Επικοινωνία</a></div>
                    </div>
                    <div className="col-md-7 d-flex justify-content-center align-items-center">
                        <img loading="lazy"
                            alt="wistleblowers"
                            src={TrustImg}
                            className="img-fluid"
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}
