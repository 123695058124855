import React from 'react'
import Hero1 from "../images/mobile/Mask group.jpg"
import Hero2 from "../images/Group 64.jpg"

export default function Hero() {
    return (
        <section className="bg-light">
            <div className="container py-0 py-lg-5">
                <div className="row p-0 pb-md-4 pb-lg-1 flex-column-reverse flex-md-row">
                    <div className="col-12 col-md-6 d-flex flex-column justify-content-around p-4">
                        <h3 className="text-primary mb-3 mb-lg-5 fs-1">Η απλή και εμπιστευτική λύση για το Whistleblowing</h3>
                        <h4 className="text-primary mb-3 mb-lg-5 fs-1 subheading">Compliant, Safe, Secure</h4>
                        <h6 className="mb-3 mb-lg-5 fs-2">Το εργαλείο που βοηθάει τις επιχειρήσεις να συμμορφωθούν με την Οδηγία της Ε.Ε.</h6>
                        <div><a className="innerNav btn btn-primary mb-4" href="#contact">Επικοινωνία</a></div>

                    </div>
                    <div className="col-12 col-md-6 p-0 d-flex justify-content-center align-items-center">
                        <img
                            alt="wistleblowers"
                            src={Hero1}
                            className="d-xl-none img-fluid w-100"
                        />
                        <img
                            alt="wistleblowers"
                            src={Hero2}
                            className="img-fluid d-none d-xl-block"
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}
